import { useContext, useState, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

import { Context } from '../contexts/Store';
import useCheckDevice from '../hooks/useCheckDevice';
import AptMarkerMP from '../components/AptMarkerMP';
import PlotMarkerMP from '../components/PlotMarkerMP';
import PlotPreview from '../components/PlotPreview';
import MapControl from '../components/MapControl';
import Alert from '../components/Alert';

import '../assets/styles/components/Masterplan.scss';
import SiteToggleButton from '../components/SiteToggleButton';
import SubMenu, { SubMenuSelect } from '../components/SubMenu';

function SitePlan(props) {
	let { state, dispatch } = useContext(Context);
	const transformComponentRef = useRef(null);
	const { id, displaySiteToggle } = props;
	const { isMobile } = useCheckDevice();

	const [plotMarkers, setPlotMarkers] = useState([]);
	const [aptMarkers, setAptMarkers] = useState([]);

	const imageRes = {
		size: isMobile ? 500 : 900,
	};

	const maxZoomScale = () => {
		return isMobile ? 8 : 4;
	};

	const [scale, setScale] = useState(1.0);

	const [imageUrl, setImageUrl] = useState('');
	const [isMounted, setIsMounted] = useState(false);
	const [isPanning, setIsPanning] = useState(false);
	const [imageLoaded, setImageLoaded] = useState(false);
	const [selectedPlotContainer, setSelectedPlotContainer] = useState(state.plotContainers[0]);
	const [phaseId, setPhaseId] = useState(0);

	let imageRef = useRef(null);

	let interactiveTimer;

	let scaleValue = imageRes?.size / imageRef?.current?.naturalWidth;
	let calcImageHeight = scaleValue * imageRef?.current?.naturalHeight;
	let difference = imageRes?.size - calcImageHeight;
	let topMargin = difference / 2;

	let calcImageWidth = scaleValue * imageRef?.current?.naturalWidth;

	useEffect(() => {
		if (id) setPhaseId(id);
	}, [id]);

	useEffect(() => {
		if (imageLoaded) {
			getPlotContainerDataForId(state.plotContainers, id);
		}
	}, [imageLoaded]);

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	useEffect(() => {
		getPlotContainerDataForId(state.plotContainers, id);
	}, [id, state.filteredHomes]);

	useEffect(() => {
		zoomToPlotMarker();
	}, [state.selectedPlotMarkerId]);

	useEffect(() => {
		if (scale == 1) dispatch({ type: 'closePlotPreview' });
	}, [scale]);

	function getPlotContainerDataForId(obj, targetId) {
		obj.forEach((plotContainerData) => {
			// if (plotContainerData.id == targetId) {
			if (plotContainerData.id == selectedPlotContainer.id) {
				setImageUrl(plotContainerData.interactiveAssetURI);

				if (imageLoaded) addMarkers(plotContainerData.interactiveRegions);

				return false;
			}
			if (Object.prototype.hasOwnProperty.call(plotContainerData, 'plotContainers')) {
				getPlotContainerDataForId(plotContainerData.plotContainers, targetId);
			}
		});
	}

	function zoomToPlotMarker() {
		if (!isMobile) {
			if (state.selectedPlotMarkerId && transformComponentRef.current) {
				const { zoomToElement } = transformComponentRef.current;
				const thisMarker = document.getElementById(state?.selectedPlotMarkerId);
				zoomToElement(thisMarker, 2.0, 1500, 'easeOut');
			}
		}
	}
	function addMarkers(interactiveRegionsArray) {
		setPlotMarkers([]);
		setAptMarkers([]);

		interactiveRegionsArray.forEach((interactiveRegion) => {
			if (interactiveRegion.isTargetAContainer) {
				const coordY = calcImageHeight * interactiveRegion.startY;
				const newPosY = topMargin + coordY;
				const newPosX = calcImageWidth * interactiveRegion.startX;

				const markerWidth = calcImageWidth * interactiveRegion.width;
				const markerHeight = calcImageHeight * interactiveRegion.height;

				const data = {
					x: newPosX,
					y: newPosY,
					width: markerWidth,
					height: markerHeight,
					id: interactiveRegion.targetId,
				};
				setAptMarkers((prevArray) => [...prevArray, data]);
			} else {
				//show all plot markers
				/*const thisPlotInfo = state.plots.find(
					(plotItem) => plotItem.id == interactiveRegion.targetId
				);*/

				//show filtered plot markers
				const thisPlotInfo = state.filteredHomes.find(
					(plotItem) => plotItem.id == interactiveRegion.targetId
				);

				if (thisPlotInfo) {
					const thisPlotStatus = state.plotStatuses.find(
						(statusItem) => statusItem.id == thisPlotInfo.plotStatusId
					);

					const coordY = calcImageHeight * interactiveRegion.startY;
					const newPosY = topMargin + coordY;
					const newPosX = calcImageWidth * interactiveRegion.startX;

					const markerWidth = calcImageWidth * interactiveRegion.width;
					const markerHeight = calcImageHeight * interactiveRegion.height;

					const data = {
						x: newPosX,
						y: newPosY,
						width: markerWidth,
						height: markerHeight,
						id: interactiveRegion.targetId,
						plotNumber: thisPlotInfo.plotNumber,
						plotStatus: thisPlotStatus.name,
						colour: thisPlotStatus.color,
					};
					setPlotMarkers((prevArray) => [...prevArray, data]);
				}
			}
		});
	}

	const ZoomOut = () => {
		const factor = Math.log((scale - 1.0) / scale);
		transformComponentRef.current.zoomOut(-factor, 300);
		setScale((thisScale) => clamp(thisScale - 1.0, 1.0, maxZoomScale()));

		if (transformComponentRef.current.state.scale < 2.0) CenterView();
	};

	const ZoomIn = () => {
		const factor = Math.log((scale + 1.0) / scale);
		transformComponentRef.current.zoomIn(factor, 300);
		setScale((thisScale) => clamp(thisScale + 1.0, 1.0, maxZoomScale()));
	};

	const CenterView = () => {
		if (transformComponentRef.current != null) {
			transformComponentRef.current.centerView(1.0, 300, 'easeOut');
			setScale(1.0);
		}
	};

	const StartInteractiveTimeout = () => {
		clearInterval(interactiveTimer);
		interactiveTimer = setInterval(() => {
			EnableMarkerInteraction();
		}, 200);
	};

	function EnableMarkerInteraction() {
		clearInterval(interactiveTimer);
		setIsPanning(false);
	}

	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	const changeSiteplan = (phaseId) => {
		setPhaseId(phaseId);

		state.plotContainers.forEach((plotContainerData) => {
			if (plotContainerData.id == phaseId) {
				setSelectedPlotContainer(plotContainerData);
				setImageUrl(plotContainerData.interactiveAssetURI);

				CenterView();

				return false;
			}
			if (Object.prototype.hasOwnProperty.call(plotContainerData, 'plotContainers')) {
				getPlotContainerDataForId(plotContainerData.plotContainers, phaseId);
			}
		});

		dispatch({
			type: 'setSelectedPhase',
			data: phaseId,
		});
	};

	const imageLoadedFunction = () => {
		setImageLoaded(true);

		scaleValue = imageRes?.size / imageRef?.current?.naturalWidth;
		calcImageHeight = scaleValue * imageRef?.current?.naturalHeight;
		difference = imageRes?.size - calcImageHeight;
		topMargin = difference / 2;
		calcImageWidth = scaleValue * imageRef?.current?.naturalWidth;

		addMarkers(selectedPlotContainer.interactiveRegions);
	};

	return (
		<>
			<Helmet>
				<title>{`Master Plan - ${state.devName.name}`}</title>
			</Helmet>

			{isMounted && (
				<>
					<div className='Masterplan' id='masterplanView'>
						<motion.div
							className={'Masterplan__Map Masterplan__Map--2d'}
							key={'MainWrapper'}
							animate={
								!isMobile
									? {
											width: `${state.isPreviewPanelOpen ? '70vw' : '100vw'}`,
									  }
									: null
							}
							onAnimationComplete={state.isPreviewPanelOpen ? zoomToPlotMarker : CenterView}
							transition={{
								x: { type: 'spring', stiffness: 300, damping: 40 },
							}}
						>
							{state.filteredHomes.length <= 0 && (
								<Alert variant='floating'>No homes match the chosen filters.</Alert>
							)}

							<TransformWrapper
								ref={transformComponentRef}
								initialScale={scale}
								maxScale={maxZoomScale()}
								centerOnInit
								onZoomStop={(ref) => {
									setScale(ref.state.scale);
									if (ref.state.scale === 1) CenterView();
								}}
								onPanning={() => {
									setIsPanning(true);
								}}
								onPanningStop={StartInteractiveTimeout}
								onZoom={(ref) => {
									setScale(ref.state.scale);
								}}
							>
								{() => (
									<>
										{!state.isPreviewPanelOpen && (
											<>
												{displaySiteToggle ? <SiteToggleButton /> : null}

												<div className='sub-menu'>
													<div className='container'>
														{state.plotContainers &&
														state.plotContainers.length > 1 &&
														!state.isPreviewPanelOpen ? (
															<SubMenu>
																<SubMenuSelect
																	list={state.plotContainers}
																	value={phaseId}
																	onChange={(e) => changeSiteplan(e.target.value)}
																	type='phase'
																></SubMenuSelect>
															</SubMenu>
														) : // currentItem ? (
														// <ItemMenu
														// 	items={multipleItems}
														// 	currentItem={`${currentId}`}
														// 	route="siteplan"
														// 	label="Siteplans"
														// />
														// ) : null
														null}
													</div>
												</div>

												<div className={'Masterplan__Controls'}>
													<MapControl
														variant='zoom-in'
														onClick={ZoomIn}
														title='Zoom in'
														disabled={scale === maxZoomScale()}
													/>
													<MapControl
														variant='zoom-out'
														onClick={ZoomOut}
														title='Zoom out'
														disabled={scale <= 1}
													/>
													<MapControl
														variant='center-map'
														onClick={CenterView}
														title='Center map'
													/>
												</div>
											</>
										)}
										<TransformComponent
											wrapperStyle={{
												width: '100%',
												height: '100%',
											}}
										>
											<motion.div
												className='zoomContainer__masterplan'
												style={{ width: `${imageRes.size}px` }}
												key={imageUrl}
												animate='center'
												exit='exit'
												transition={{
													y: { type: 'spring', stiffness: 300, damping: 40 },
												}}
											>
												<img
													ref={imageRef}
													onLoad={() => imageLoadedFunction()}
													style={{ width: `${imageRes.size}px`, height: `${imageRes.size}px` }}
													//onLoad={(e) => console.log(e.target)}
													src={imageUrl}
													alt={imageUrl}
													id={selectedPlotContainer.id}
												/>

												{aptMarkers.map((aptMarker) => (
													<div key={aptMarker.id.toString()}>
														<AptMarkerMP
															posX={aptMarker.x}
															posY={aptMarker.y}
															markerWidth={aptMarker.width}
															markerHeight={aptMarker.height}
															id={aptMarker.id}
															isPanning={isPanning}
														/>
													</div>
												))}

												{plotMarkers.map((plotMarker) => (
													<div key={plotMarker.id.toString()}>
														<PlotMarkerMP
															posX={plotMarker.x}
															posY={plotMarker.y}
															markerWidth={plotMarker.width}
															markerHeight={plotMarker.height}
															plotId={plotMarker.id}
															plotNum={plotMarker.plotNumber}
															plotColour={plotMarker.colour}
															isPanning={isPanning}
														/>
													</div>
												))}
											</motion.div>
										</TransformComponent>
									</>
								)}
							</TransformWrapper>
						</motion.div>
						<PlotPreview />
					</div>
				</>
			)}
		</>
	);
}

export default SitePlan;

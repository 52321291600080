import React, { useContext } from 'react';
import { motion } from 'framer-motion';

import { Context } from '../contexts/Store';
import '../assets/styles/components/DimsCards.scss';

function Dims(props) {
	const { data } = props;
	const { state, dispatch } = useContext(Context);
	const { metricSystem } = state;

	let dims = Object.assign([], data.dimensions);

	const buttonBubble = (
		<motion.div
			layoutId='DimsCards__Units__Bubble'
			className='DimsCards__Units__Bubble'
			initial={false}
			animate={{
				backgroundColor: 'var(--menu-secondary-color)',
				opacity: 0.6,
			}}
		/>
	);

	return (
		<div className={`DimsCards`}>
			<div className={`DimsCards__Rooms`}>
				{dims.map((item) => {
					return (
						<div className={`DimsCards__Room`} key={item.id}>
							<div className={`DimsCards__Room__Item`}>
								<strong>{item.name}</strong>
							</div>
							{!metricSystem && (
								<div className={`DimsCards__Room__Item`}>{item.imperialDimensions}</div>
							)}
							{metricSystem && (
								<div className={`DimsCards__Room__Item`}>{item.metricDimensions}</div>
							)}
						</div>
					);
				})}
			</div>
			<div className='DimsCards__Units'>
				<button
					className={`DimsCards__Units__Button ${
						!metricSystem && 'DimsCards__Units__Button--active'
					}`}
					onClick={() => dispatch({ type: 'setMetricSystem', data: !metricSystem })}
				>
					{!metricSystem && buttonBubble}
					ft
				</button>
				<button
					className={`DimsCards__Units__Button ${
						metricSystem && 'DimsCards__Units__Button--active'
					}`}
					onClick={() => dispatch({ type: 'setMetricSystem', data: !metricSystem })}
				>
					{metricSystem && buttonBubble}m
				</button>
			</div>
			<div className='DimsCards__Disclaimer'>{data.disclaimerText}</div>
		</div>
	);
}

export default Dims;

import React, { useContext, useEffect, useRef } from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';

import { Context } from '../contexts/Store';
import { findFloorplans } from '../helpers/utilities';

import PlotHeader from './PlotHeader';
import ToggleButton from './ToggleButton';
import FavButton from './FavButton';
import Locator from './Locator';

import '../assets/styles/components/CompareCard.scss';

function CompareCard({ plot }) {
	const { state, dispatch } = useContext(Context);
	const ref = useRef(null);

	const isApartment = plot.floorData?.floorName;
	const selectedItemsAssets = state.plots
		.filter((item) => item.id === plot.id)
		.map((item) => item.plotType.assets);
	const mainImage = selectedItemsAssets.map((assets) => assets.filter((img) => img.order === 1))[0];
	let floorplans = findFloorplans(selectedItemsAssets[0]);

	// If floorplans in plot specific assets, use them instead
	if (plot.plotSpecificAssets) {
		const plotSpecificFloorplans = findFloorplans(plot.plotSpecificAssets);

		if (plotSpecificFloorplans.length) {
			floorplans = plotSpecificFloorplans;
		}
	}

	// Setup measurement units toggle buttons
	const units = [
		{ label: 'm', value: 'metric' },
		{ label: 'ft', value: 'imperial' },
	];

	const currentUnit = !state.metricSystem ? 'imperial' : 'metric';

	// Scroll to top on uncompare
	useEffect(() => {
		if (ref.current) {
			ref.current.scrollTo({ behavior: 'smooth', top: 0 });
		}
	}, [state.comparePlots]);

	// Plot data not yet loaded
	if (!plot) {
		return <>Loading</>;
	}

	return (
		<div className='CompareCard'>
			<ScrollSyncPane>
				<div
					className={`CompareCard__Inner ${
						state.compareShowDims ? 'CompareCard__Inner--Dims' : ''
					}`}
					ref={ref}
				>
					<PlotHeader plot={plot} />

					<div className='CompareCard__Images'>
						{mainImage.map((image) => {
							return (
								<img
									src={image.fileUrl}
									alt={image.name}
									className='CompareCard__Image'
									key={image.assetId}
								/>
							);
						})}
					</div>

					<div className='CompareCard__Section'>
						{isApartment ? (
							<h3 className='CompareCard__Subheading'>Floor Location</h3>
						) : (
							<h3 className='CompareCard__Subheading'>Site Location</h3>
						)}
						<Locator containerId={plot.id} />
					</div>

					{floorplans && (
						<div className='CompareCard__Floorplans'>
							<h3 className='CompareCard__Subheading'>Floorplans</h3>
							{floorplans.map((image) => {
								return (
									<div className='CompareCard__Floorplan' key={image.assetId}>
										<img src={image.fileUrl} alt={image.name} />
										<div className='CompareCard__Caption'>{image.caption}</div>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</ScrollSyncPane>

			{state.compareShowDims && (
				<>
					<div className='CompareCard__DimsControls'>
						{units.map((el, key) => (
							<ToggleButton
								size='sm'
								active={currentUnit === el.value}
								onClick={() => dispatch({ type: 'setMetricSystem', data: !state.metricSystem })}
								key={key}
							>
								{el.label}
							</ToggleButton>
						))}
					</div>
					<div className='CompareCard__Dims'>
						<div className='CompareCard__Dims__Inner'>
							{plot.plotType.dimensions.map((room) => {
								return (
									<div className='CompareCard__Dim' key={room.id}>
										<p className='CompareCard__Dim__Name'>{room.name}</p>

										{!state.metricSystem && (
											<div className={`CompareCard__Dim__Value`}>{room.imperialDimensions}</div>
										)}
										{state.metricSystem && (
											<div className={`CompareCard__Dim__Value`}>{room.metricDimensions}</div>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</>
			)}

			<div className='CompareCard__Footer'>
				<span className='CompareCard__Footer__PlotName'>{plot.plotType.name}</span>
				<FavButton plotID={plot.id}>Favourite</FavButton>
				<ToggleButton
					icon='close'
					variant='secondary'
					size='sm'
					onClick={() => dispatch({ type: 'toggleComparePlot', data: plot.id })}
				/>
			</div>
		</div>
	);
}

export default CompareCard;

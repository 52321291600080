import React, { useContext, useState, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { DragDropContainer } from 'react-drag-drop-container';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Context } from '../contexts/Store';

import GetSpinFrame from '../components/GetSpinFrame';
import PlotPreview from '../components/PlotPreview';
import MapControl from '../components/MapControl';
import Alert from '../components/Alert';

import '../assets/styles/components/SiteplanSpin.scss';
import '../assets/styles/components/TwelveFrameButton.scss';
import { ReactComponent as VrPersonIcon } from '../assets/img/vr-person-icon.svg';
import { ReactComponent as SpinNavigationIcon } from '../assets/img/twelveframe-arrow.svg';
import useCheckDevice from '../hooks/useCheckDevice';
import SiteToggleButton from '../components/SiteToggleButton';
import { SpinPrompt } from '../components/SpinPrompt';
import SubMenu, { SubMenuSelect } from '../components/SubMenu';

import { getNoHeaders } from '../adapters/xhr';

let doOnceFlag = false;

const MAX_SCALE_ZOOM = 5;
// const INITIAL_SCALE = 2;

function SiteSpin(props) {
	const { state, dispatch } = useContext(Context);

	const navigate = useNavigate();
	// const goToRoute = (location) => navigate(location);
	const [phaseId, setPhaseId] = useState(props.id);
	const [id, setId] = useState(props.id);
	const [showStreetButton, setShowStreetButton] = useState(false);
	const { plotContainers } = state;
	const { isMobile } = useCheckDevice();
	const lockYaxis = state.axis !== 'y';
	const INITIAL_SCALE = state.initialZoom ? state.initialZoom : 2;

	const imageRes = {
		lowRes: isMobile ? 'mobile' : '1k',
		hiRes: isMobile ? '2k' : '4k',
		size: isMobile ? '800px' : '2000px',
	};

	const [frameNumberData, setFrameNumberData] = useState({
		frameString: '',
		frameNumber: 0,
	});

	let interactiveTimer;

	const transformComponentRef = useRef(null);
	const [scale, setScale] = useState(INITIAL_SCALE);
	const [thisSpinConfig, setThisSpinConfig] = useState(false);
	const [isPressed, setIsPressed] = useState(false);
	const [isMounted, setIsMounted] = useState(false);
	const disableSpin = scale > INITIAL_SCALE ? true : false;
	const [vrMarkerActive, setVrMarkerActive] = useState(false);
	const [isPanning, setIsPanning] = useState(false);
	const [hidePrompt, setHidePrompt] = useState(false);
	const userAngles = JSON.parse(sessionStorage?.getItem('userSpinAngles'));
	const [spinFrame, setSpinFrame] = useState(userAngles ? userAngles?.userRotate : 0);

	useEffect(() => {
		if (props.id) {
			setPhaseId(props.id);
		}
	}, [props.id]);

	const {
		spinConfigData,
		selectedPlotMarkerId,
		isPreviewPanelOpen,
		devSpinPath,
		devId,
		devName,
		isTabletDevice,
		filteredHomes,
	} = state;

	const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

	useEffect(() => {
		if (!props.isTwelveFrame) return;
		setIsPressed(false);
	}, [spinFrame]);

	const [position, setPosition] = useState({
		prevX: 0,
		prevY: 0,
		deltaX: 0,
		deltaY: 0,
	});

	const [angles, setAngles] = useState({
		rotate: 0,
		tilt: 0,
		lastRotate: 0,
		lastTilt: 0,
		res: 'hiRes',
	});

	useEffect(() => {
		setIsMounted(true);
		return () => {
			setIsMounted(false);
		};
	}, []);

	useEffect(() => {
		spinConfigData.every((arrayItem) => {
			if (arrayItem.spinId == props.id) {
				setThisSpinConfig(arrayItem.spinConfigData);
				return false;
			}
			return true;
		});
	}, [spinConfigData]);

	//set default rotation angle
	useEffect(() => {
		const userAngles = JSON.parse(sessionStorage?.getItem('userSpinAngles'));

		if (thisSpinConfig) {
			if (props.isTwelveFrame) {
				const heroAngle = parseInt(thisSpinConfig?.startRotation / 30);
				setSpinFrame(heroAngle);
			} else {
				setAngles((angle) => ({
					...angle,
					rotate: userAngles ? userAngles.userRotate : parseInt(thisSpinConfig.startRotation),
					tilt: userAngles ? userAngles.userTilt : parseInt(thisSpinConfig.startTilt),
				}));
			}
		}
	}, [thisSpinConfig]);

	const onMouseDownHandler = (e) => {
		setHidePrompt(true);
		if (disableSpin) return;
		e.preventDefault();
		setIsPressed(true);
		setPosition((prev) => ({
			...prev,
			prevX: e.clientX,
			prevY: e.clientY,
			deltaX: 0,
			deltaY: 0,
		}));

		setAngles((angle) => ({
			...angle,
			lastRotate: angle.rotate,
			lastTilt: angle.tilt,
		}));
	};

	const onMouseMoveHandler = (e) => {
		if (!isPressed || disableSpin) return;
		e.preventDefault();
		setPosition((prev) => ({
			...prev,
			deltaX: (e.clientX - prev.prevX) * 0.3,
			deltaY: (e.clientY - prev.prevY) * 0.5,
		}));

		setAngles((angle) => ({
			...angle,
			rotate: (angle.lastRotate + position.deltaX) % 360,
			res: 'lowRes',
		}));

		if (lockYaxis) {
			setAngles((angle) => ({
				...angle,
				tilt: clamp(angle.lastTilt + position.deltaY, 30, isMobile ? 30 : 90),
			}));
		}
	};

	const onTouchDownHandler = (e) => {
		setHidePrompt(true);
		if (disableSpin) return;

		setIsPressed(true);
		setPosition((prev) => ({
			...prev,
			prevX: e.touches[0].clientX,
			prevY: e.touches[0].clientY,
			deltaX: 0,
			deltaY: 0,
		}));

		setAngles((angle) => ({
			...angle,
			lastRotate: angle.rotate,
			lastTilt: angle.tilt,
		}));
	};

	const onTouchMoveHandler = (e) => {
		if (!isPressed || disableSpin) return;
		setPosition((prev) => ({
			...prev,
			deltaX: (e.touches[0].clientX - prev.prevX) * 0.3,
			deltaY: (e.touches[0].clientY - prev.prevY) * 0.5,
		}));

		setAngles((angle) => ({
			...angle,
			rotate: (angle.lastRotate + position.deltaX) % 360,
			res: 'lowRes',
		}));

		if (lockYaxis) {
			setAngles((angle) => ({
				...angle,
				tilt: clamp(angle.lastTilt + position.deltaY, 30, isMobile ? 30 : 90),
			}));
		}
	};

	const recordSpinPosition = () => {
		if (disableSpin) return;
		setIsPressed(false);
		sessionStorage.setItem(
			'userSpinAngles',
			JSON.stringify({ userRotate: angles.rotate, userTilt: angles.tilt })
		);

		setAngles((angle) => ({
			...angle,
			lastRotate: angle.rotate,
			lastTilt: angle.tilt,
			res: 'hiRes',
		}));
	};

	//handle twelve frame or 360 spin
	const spinFrameString = spinFrame.toString().padStart(2, '0');
	const path = props.isTwelveFrame
		? `12frames/spin_${spinFrameString}`
		: `${imageRes[angles.res]}/${'spin_'}${frameNumberData.frameString}`;

	const spinImageUrl = `${devSpinPath}${devId}/${id}/${path}.jpg`;

	useEffect(() => {
		//USE THIS FOR FULL 630 FRAMES
		const rotationSteps = 90;
		const n = (rotationSteps * parseInt(-angles.rotate)) / 360;
		const steppedRotationAngle = (360 * n) / rotationSteps;
		const framePanAngle = (parseInt(steppedRotationAngle) + 360) % 360;
		const frameNumberPan = (rotationSteps * parseInt(framePanAngle)) / 360;
		const tiltIntervals = 7;
		const m = (tiltIntervals * parseInt(angles.tilt)) / rotationSteps;
		const steppedTiltAngle = m * (rotationSteps / tiltIntervals);
		const frameNumberTilt = 7 * (1 - parseInt(steppedTiltAngle) / 90.0);
		const spinFrameNumber = parseInt(frameNumberPan) * 7 + parseInt(frameNumberTilt);
		const spinFrameString = spinFrameNumber.toString().padStart(2, '0');

		setFrameNumberData((frame) => ({
			...frame,
			frameNumber: spinFrameNumber,
			frameString: spinFrameString,
		}));
	}, [angles]);

	useEffect(() => {
		window.scrollTo(0, 0);
		return () => {
			clearAllBodyScrollLocks();
		};
	}, []);

	useEffect(() => {
		if (transformComponentRef.current) disableBodyScroll(transformComponentRef.current);
	}, [transformComponentRef.current]);

	useEffect(() => {
		zoomToPlotMarker();
	}, [selectedPlotMarkerId]);

	useEffect(() => {
		if (scale == 1.0 && isPreviewPanelOpen) dispatch({ type: 'closePlotPreview' });
	}, [scale]);

	const ZoomOut = () => {
		const factor = Math.log((scale - 1.0) / scale);
		transformComponentRef.current.zoomOut(-factor, 300);
		setScale((thisScale) => clamp(thisScale - 1, 1, MAX_SCALE_ZOOM));

		//if (transformComponentRef.current.state.scale === 2.0) CenterView();
	};

	const ZoomIn = () => {
		setHidePrompt(true);
		const factor = Math.log((scale + 1.0) / scale);
		transformComponentRef.current.zoomIn(factor, 300);
		setScale((thisScale) => clamp(thisScale + 1, 1, MAX_SCALE_ZOOM));
	};

	const CenterView = () => {
		if (transformComponentRef.current != null) {
			transformComponentRef.current.centerView(INITIAL_SCALE, 300, 'easeOut');
			setScale(INITIAL_SCALE);
		}
	};

	function zoomToPlotMarker() {
		if (!isMobile) {
			if (state.selectedPlotMarkerId && transformComponentRef.current) {
				const { zoomToElement } = transformComponentRef.current;
				const thisMarker = document.getElementById(selectedPlotMarkerId);
				setScale(2.0);
				zoomToElement(thisMarker, 2.0, 1500, 'easeOut');
			}
		}
	}

	const StartInteractiveTimeout = () => {
		clearInterval(interactiveTimer);
		interactiveTimer = setInterval(() => {
			EnableMarkerInteraction();
		}, 200);
	};

	function EnableMarkerInteraction() {
		clearInterval(interactiveTimer);
		setIsPanning(false);
	}

	const drop = (event) => {
		var _phase = location.pathname.replace('/site/', '');
		if (!doOnceFlag) {
			doOnceFlag = true;

			if (plotContainers && plotContainers.length > 1 && _phase !== '/site') {
				let _selectedPhase = plotContainers.filter((e) => e.id === Number(_phase))[0];
				// goToRoute('/exterior-tour/' + _selectedPhase.name.replace(" ", "-").toLowerCase() + '/' + event.dropData);
				navigate(
					'/exterior-tour/' +
						_selectedPhase.name.replace(' ', '-').toLowerCase() +
						'/' +
						event.dropData,
					{
						state: { previousPath: location.pathname },
					}
				);
			} else {
				// goToRoute('/exterior-tour/current/' + event.dropData);
				navigate('/exterior-tour/current/' + event.dropData, {
					state: { previousPath: location.pathname },
				});
			}
		}
		setVrMarkerActive(false);
	};

	const drag = () => {
		setHidePrompt(true);
		setVrMarkerActive(true);
	};

	const dragEnded = () => {
		doOnceFlag = false;
		setVrMarkerActive(false);
	};

	const changeSiteplan = (phaseId) => {
		const _selectedPhase = spinConfigData.filter((e) => Number(e.spinId) === Number(phaseId));
		setId(phaseId);
		setPhaseId(phaseId);
		setThisSpinConfig(_selectedPhase[0].spinConfigData);

		dispatch({
			type: 'setSelectedPhase',
			data: phaseId,
		});

		getNoHeaders(`${state.devSpinPath}${state.devId}/${phaseId}/spinExport.json`).then(
			(response) => {
				if (response.data) {
					const tempObj = { spinId: phaseId, spinData: response.data };
					dispatch({
						type: 'setSpinMarkerData',
						data: tempObj,
					});

					dispatch({
						type: 'setSelectedPhaseName',
						data: plotContainers
							.filter((e) => e.id === Number(phaseId))[0]
							.name.replace(' ', '-')
							.toLowerCase(),
					});
				}
			}
		);

		if (thisSpinConfig) {
			setAngles((angle) => ({
				...angle,
				rotate: parseInt(thisSpinConfig.startRotation),
				tilt: parseInt(thisSpinConfig.startTilt),
			}));
		}

		props.onPhaseChange(phaseId);

		navigate('/site/' + phaseId);
	};

	const updatePhaseId = () => {
		setShowStreetButton(true);
	};

	//twelve frame functions
	const negMod = (num) => ((num % 12) + 12) % 12;

	const storeUserAngle = () => {
		sessionStorage.setItem(
			'userSpinAngles',
			JSON.stringify({ userRotate: spinFrame, userTilt: 0 })
		);
	};

	function rotateSiteplanLeft() {
		setIsPressed(true);
		setHidePrompt(true);
		setSpinFrame((thisValue) => (thisValue + 1) % 12);
	}

	function rotateSiteplanRight() {
		setIsPressed(true);
		setHidePrompt(true);
		setSpinFrame((thisValue) => negMod(thisValue - 1));
	}

	return (
		<>
			{<SpinPrompt hidden={hidePrompt} />}
			{props.isTwelveFrame && storeUserAngle()}
			<Helmet>
				<title>{`Siteplan - ${devName.name}`}</title>
			</Helmet>

			{isMounted && (
				<>
					<div className='Masterplan'>
						<motion.div
							className={'Masterplan__Map'}
							key={'MainWrapper'}
							animate={
								!isMobile
									? { width: isPreviewPanelOpen && !isTabletDevice ? '70vw' : '100vw' }
									: null
							}
							onAnimationComplete={state.isPreviewPanelOpen ? zoomToPlotMarker : CenterView}
							transition={{
								x: { type: 'spring', stiffness: 300, damping: 40 },
							}}
						>
							<TransformWrapper
								ref={transformComponentRef}
								initialScale={scale}
								maxScale={MAX_SCALE_ZOOM}
								limitToBounds={true}
								onZoomStop={(ref) => {
									setScale(ref.state.scale);
									if (ref.state.scale === 1) CenterView();
								}}
								panning={{ disabled: !disableSpin }}
								onPanning={() => {
									setIsPanning(true);
								}}
								onPanningStop={() => {
									StartInteractiveTimeout();
								}}
								centerOnInit
								onZoom={(ref) => {
									setScale(ref.state.scale);
								}}
							>
								{() => (
									<>
										{!isPreviewPanelOpen && (
											<>
												{props.isTwelveFrame && (
													<>
														<button
															className='twelveFrameButtonLeft'
															onClick={() => rotateSiteplanLeft()}
														>
															<SpinNavigationIcon />
														</button>

														<button
															className='twelveFrameButtonRight'
															onClick={() => rotateSiteplanRight()}
														>
															<SpinNavigationIcon />
														</button>
													</>
												)}
												<SiteToggleButton />

												<div className='sub-menu'>
													<div className='container'>
														{spinConfigData && spinConfigData.length > 1 && !isPreviewPanelOpen ? (
															<SubMenu>
																<SubMenuSelect
																	list={plotContainers}
																	value={phaseId}
																	onChange={(e) => changeSiteplan(e.target.value)}
																	type='phase'
																></SubMenuSelect>
															</SubMenu>
														) : // currentItem ? (
														// <ItemMenu
														// 	items={multipleItems}
														// 	currentItem={`${currentId}`}
														// 	route="siteplan"
														// 	label="Siteplans"
														// />
														// ) : null
														null}
													</div>
												</div>

												<div className='Masterplan__Controls'>
													<MapControl
														variant='zoom-in'
														onClick={ZoomIn}
														title='Zoom in'
														disabled={scale === MAX_SCALE_ZOOM}
													/>
													<MapControl
														variant='zoom-out'
														onClick={ZoomOut}
														title='Zoom out'
														disabled={scale <= 1}
													/>
													<MapControl
														variant='center-map'
														onClick={CenterView}
														title='Center map'
													/>
												</div>
											</>
										)}
										<TransformComponent
											wrapperStyle={{
												width: '100%',
												height: '100%',
											}}
										>
											<div
												className='zoomContainer__spin'
												id='spin'
												onMouseDown={onMouseDownHandler}
												onMouseMove={onMouseMoveHandler}
												onMouseUp={recordSpinPosition}
												onTouchStart={onTouchDownHandler}
												onTouchMove={onTouchMoveHandler}
												onTouchEnd={recordSpinPosition}
											>
												<img
													src={spinImageUrl}
													alt={`${id}_${frameNumberData.frameString}`}
													onLoad={() => updatePhaseId()}
												/>

												<GetSpinFrame
													frameNumber={
														props.isTwelveFrame ? spinFrame : frameNumberData.frameNumber
													}
													spinAssetId={id}
													scale={scale}
													tilt={props.isTwelveFrame ? 30 : angles.tilt}
													showVrMarkers={!isPressed}
													mobile={isMobile}
													vrMkrActive={vrMarkerActive}
													isPanning={props.isTwelveFrame ? false : isPanning}
													isTwelveFrame={props.isTwelveFrame}
												/>
											</div>
										</TransformComponent>

										{filteredHomes.length <= 0 && (
											<Alert variant='floating'>No homes match the chosen filters.</Alert>
										)}
									</>
								)}
							</TransformWrapper>
						</motion.div>
						<PlotPreview />
					</div>
					{!isPreviewPanelOpen && showStreetButton ? (
						<div id={props.selectedPhaseId}>
							<DragDropContainer
								id='vrMarkers'
								targetKey='vr_marker'
								onDrop={() => drop(event)}
								onDrag={drag}
								onDragEnd={() => dragEnded()}
							>
								<div className='EnableStreetview'>
									<span className='EnableStreetview__Icon'>
										<VrPersonIcon />
									</span>
									<span className='EnableStreetview__Label'>Street View</span>
								</div>
							</DragDropContainer>
						</div>
					) : null}
				</>
			)}
		</>
	);
}

export default SiteSpin;
